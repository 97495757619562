import React from "react";
import Header from "../comp/header";
import Hero from "../comp/hero";
import Footer from "../comp/footer";
import Board from "./leadership/board";

const Lead = () =>{
    return(
        <section className="lead">
            <Header/>
            <div className="holding">
                <Hero title={"Our Leadership"}/>
                <Board/>
            </div>
            <Footer/>
        </section>
    )
}

export default Lead;