import React from "react";
import scale from '../../images/scale.svg';
import rel from '../../images/rel.svg';
import tec from '../../images/tec.svg';
import open from '../../images/open.svg';
import agile from '../../images/agile.svg';
import { Link } from "react-router-dom";


const Service = () =>{
    return(
        <section className="service">
            <div className="top">
                <h4>OUR SERVICES</h4>
                <h2>
                    At Tech4mation LTD, we are the industry leaders in providing:
                </h2>
            </div>
            <div className="bot">
                <div className="grider">
                    <div className="item1">
                        <img src={scale} alt="" />
                        <span>
                            <h4>
                                Scalability for Growth
                            </h4>
                            <p>
                                Our platforms can easily scale up by adding more servers to match your capacity requirements
                            </p>
                        </span>
                    </div>
                    <div className="item2">
                        <img src={rel} alt=""></img>
                        <span>
                            <h4>Reliability and Redundancy</h4>
                            <p>
                                Clustering and georedundancy for high availability configuration and zero downtime updates
                            </p>
                        </span>
                    </div>
                    <div className="item3">
                        <img src={tec} alt=""></img>
                        <span>
                            <h4>Technical 24/7 Support </h4>
                            <p>
                                Over 60% of our engineers are in the technical support services, and they work 24/7 to deliver consistent support
                            </p>
                        </span>
                    </div>
                    <div className="item4">
                        <img src={open} alt=""></img>
                        <span>
                            <h4>Open Architecture</h4>
                            <p>
                                Tech4mation provides both APIs and standardized integration protocols for their softswitch solutions to allow for easy integration with any customer equipment specification.
                            </p>
                        </span>
                    </div>
                    <div className="item5">
                        <img src={agile} alt=""></img>
                        <span>
                            <h4>Agile Development</h4>
                            <p>
                                Tech4mation delivers constant software updates periodically, to keep the system up to date with the latest technologies and security features
                            </p>
                        </span>
                    </div>
                </div>
            </div>

            <Link to="/about">Learn More</Link>
        </section>
    )
}

export default Service;