import React from "react";
import { Link } from "react-router-dom";
import balls from '../images/balls.svg';

// Hero.js

const Hero = ({ title, subtitle, clicker }) => {
  return (
    <section className="hero">
      <div>
        <h1>{title}</h1>
        {subtitle && <p style={{ display: subtitle ? "block" : "none" }}>{subtitle}</p>}
        {clicker && <Link to="/about" className="link" style={{ display: clicker ? "block" : "none" }}>{clicker}</Link>}
      </div>

      <img src={balls} alt="" />
    </section>
  );
};

Hero.defaultProps = {
  subtitle: '',
  clicker:'' // Provide a default value for subtitle
};

export default Hero;
