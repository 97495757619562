import React from "react";
import Marquee from 'react-fast-marquee';
// import opera from '../../images/opera.png';
// import palm from '../../images/palm.png';
// import saair from '../../images/saair.png';
// import cos from '../../images/cos.png';
// import nb from '../../images/nb.png';
import bet from '../../images/betway.png';
import lead from '../../images/leadCity.png';
import mob9 from '../../images/9mob.png';
import connex from '../../images/connex.png';
import uba from '../../images/uba.png';
import live from '../../images/live.png';
import show from '../../images/show.png';
import sporty from '../../images/sporty.png';
import qfa from '../../images/qfa.png';

// import mob9 from '../../images/9mob.png';



const Clients = () =>{

    const items = [ bet, lead, mob9, connex, uba, live, show, sporty, qfa, bet, lead, mob9, connex, uba, live, show, sporty, qfa, bet, lead, mob9, connex, uba, live, show, sporty, qfa];


    return(
        <section className="clients" style={{width: '100%', display: 'flex', flexDirection: 'column', padding:'20px 60px', justifyContent: 'center', alignItems: 'center'}}>
            <h1>
                Join Our Satisfied Clients
            </h1>
            <Marquee className="Marquee">
                <div className="img" >
                    {items.map((imageUrl, index) => (
                        <div key={index} style={{ marginRight: '20px' }}>
                            <img src={imageUrl} alt={index + 1} style={{ maxWidth: '100%', height: 'auto' }} />
                        </div>
                    ))}
                </div>
            </Marquee>
        </section>
    )
}

export default Clients;