import React from "react";
import Header from "../comp/header";
import Hero from "../comp/hero";
import Lhold from "./service/holdL";
import Rhold from "./service/holdR";
import Footer from "../comp/footer";
import smart from '../images/smart.png';
import green from '../images/green.png';
import enter from '../images/enter.png';
import wifi from '../images/wifi.png';
import future from '../images/future.png';
import app from '../images/app.png';
import smartM from '../images/smartMob.png';
import greenM from '../images/greenMob.png';
import enterM from '../images/enterMob.png';
import wifiM from '../images/wifiMob.png';
import futureM from '../images/futureMob.png';
import appM from '../images/appMob.png';





const Serv = () =>{
    return(
        <section className="serv">
            <Header/>
            <div className="holding">
                <Hero title={"Our Services"} subtitle={"We provide innovative solutions to your unique needs"}/>
                <Lhold head={"Smart Security Solutions"} sub={"We provide smart security solutions to homes, offices and industries. They include:"} list1={"Closed Circuit Television (CCTV)"} list2={"Intruder Detection System"} list3={"Access Monitoring and Control"} img={smart} mob={smartM}/>
                <Rhold head={"Wi-Fi Internet Services"} sub={"We provide high-speed internet to home and offices:"} list1={"5G+ LTE Services"} list2={"Unlimited Bandwidth"} list3={"Optical Fibre Connection"} img={wifi} mob={wifiM}/>
                <Lhold head={"Green Energy Solutions"} sub={"We provide round the clock energy to homes, offices and industries with:"} list1={"Solar Panels Installation"} list2={"Inverter Backup Systems"} list3={"Alternative Renewable Energy"} img={green} mob={greenM}/>
                <Rhold head={"Futuristic Home Solutions"} sub={"We transform houses into comfortable futuristics homes with:"} list1={"Multi Room AV Design"} list2={"Luxury Home Cinemas"} list3={"Intelligent Home Lighting."} img={future} mob={futureM}/>
                <Lhold head={"Entreprise Communication Solutions"} sub={"We ensure the smooth operation of offices and industries by providing:"} list1={"Customer Contact Solutions"} list2={"Unified Communication Services"} list3={"IP PBX Systems"} img={enter} mob={enterM}/>
                <Rhold head={"Application Development Services"} sub={"We convert brilliant ideas into reality on numerous platforms:"} list1={"Websites and Web Applications"} list2={"Desktop Application"} list3={"Mobile Applications"} img={app} mob={appM}/>
            </div>
            <Footer/>
        </section>
    )
}

export default Serv;