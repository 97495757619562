import React from "react";

const Lhold = ({head, sub, list1, list2, list3, img, mob}) =>{
    return(
        <section className="lHold">
            <div className="img"><img src={img} alt="" /></div>
            <div className="img2"><img src={mob} alt="" /></div>
            <div className="text">
                <h2>{head}</h2>
                <p>{sub}</p>
                <ul>
                    <li><div className="red"></div>{list1}</li>
                    <li><div className="red"></div>{list2}</li>
                    <li><div className="red"></div>{list3}</li>
                </ul>
            </div>
        </section>
    )
}

export default Lhold;