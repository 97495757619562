import React, { useState } from "react";

const MisVis = () =>{
    const [selectedTab, setSelectedTab] = useState("mission");

    const handleClick = (tab) => {
    setSelectedTab(tab);
    };

    return(
        <section className="misVis">
            <div className="top">
                <div  className={`mis ${selectedTab === "mission" ? "active" : "inactive"}`} onClick={() => handleClick("mission")}>
                    <h1>OUR MISSION</h1>
                </div>
                <div className={`vis ${selectedTab === "vision" ? "active" : "inactive"}`} onClick={() => handleClick("vision")}>
                    <h1>OUR VISION</h1>
                </div>
            </div>
            <div className="bot">
                <p style={{ display: selectedTab === "mission" ? "block" : "none" }}>
                    Our mission at Tech4mation is to connect people and businesses across Africa by providing high-quality internet and telecommunication services, as well as innovative software products that enable our customers to communicate, collaborate and grow.
                </p>
                <p style={{ display: selectedTab === "vision" ? "block" : "none" }}>
                    Our vision at Tech4mation is to be the leading technology company in Africa, delivering fast, reliable, and affordable internet and communication services to individuals and organisations, while empowering businesses with state-of-the-art software products that enhance their operations and productivity.
                </p>
            </div>
        </section>
    )
}

export default MisVis;