import React from "react";

const Who = () =>{
    return(
        <section className="who">
            <div className="img"></div>
            <div className="text">
                <h1>Who We Are</h1>
                <p>
                    Tech4mation LTD is an indigenous Information Technology Company based in Lagos State, with branches in Abuja and Anambra, Nigeria. Established in 2010, our areas of competence include Information Technology Solutions, Audio-visual solutions and Energy solutions for Telecommunication companies and production industries and residences. We are renowned for providing interactive, innovative and intuitive solutions such as residential and commercial automated AV systems and integrated electronic installations.
                </p>
            </div>
        </section>
    )            
}

export default Who;