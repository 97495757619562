import React, {useState} from "react";
import ify from '../../images/IFY .jpg';
import biodun from '../../images/biodun.jpeg';
import ugo from '../../images/ugo.png';
import viv from '../../images/viv.png';
import vic from '../../images/vic.png';
import grace from '../../images/grace.jpeg';
import prod from '../../images/prod.png';
import sales from '../../images/sales.png';
import finance from '../../images/finance.png';
import x from '../../images/iconoir_cancel.svg';


const Board = () =>{
    const boardMembers = [
        { image: ify, name: "Ifeanyi Adirika", role: "Chief Operating Officer", text:"As the Chief Operating Officer at Tech4mation Ltd., Ifeanyi Adirika stands at the forefront of the telecommunications industry in Nigeria. His journey is adorned with the achievements of a seasoned Co-Founder, bringing a wealth of experience to the company's leadership. Mr. Adirika's expertise spans key areas, including Linux System Administration, Cisco Systems Products, Internet Protocol Suite (TCP/IP), Web Applications, and VOIP systems. With a degree in Engineering focused on Telecommunication Networks and certification as a Cisco Certified Network Administrator, he embodies a strong information technology professional. Under Mr. Adirika's stewardship, Tech4mation Ltd. has flourished, becoming a beacon of innovation in the ever-evolving ICT landscape. His strategic vision and technical acumen have been instrumental in positioning the company as a trailblazer, consistently delivering cutting-edge solutions to meet the demands of the telecommunications industry." },
        { image: biodun, name: "Abiodun Adedayo ", role: "Director ", text:"Adedayo is a seasoned Executive-Level entrepreneur with a passion to help customers harness value in areas of Technology Management, R&D and Business Development. Dayo’s experience has enforced measurable stakeholder value through the strategic implementation of relationship building and project execution methodologies in IT. Dayo has worked in the Telecom industry for over 30 years with global leaders such as Lucent Technologies, Nortel Networks, CitiBank, Acme packet (now Oracle), Aicent and Syniverse Technologies. With his extensive experience in technology and telecommunications in sub-Saharan Africa, Adedayo has built strategic business relationship and knowledge in one of the fastest growing emerging telecommunication markets. Adedayo has functioned at different capacities in areas such as research and development, technology advisor & evangelist and contributing author to various working group within the mobile and wireless bodies." },
        { image: viv, name: "Vivian Ubochi", role: "Mgt consultant", text:"At the nexus of leadership and project management in the telecommunications industry, Vivian Ubochi serves as the Agile Project Manager for Tech4mation Limited. With a multifaceted skill set, she brings a dynamic approach to product planning, launch, and project execution. Vivian's proficiency extends to Agile methodologies, Leadership, Business Planning, Project Planning, Digital Strategy, Google Workplace, Telecommunications, Product Requirements, Customer Relationship Management (CRM), Trello, Operations Management, and Scrum. Her role encapsulates the entire product lifecycle, from conception to launch, with a keen focus on efficiency and excellence. In the competitive landscape of the ICT/telecoms industry, Vivian's strategic insights and leadership acumen contribute significantly to Tech4mation Limited's success. Her ability to navigate complex projects and implement agile practices positions the company as a leader in delivering innovative solutions to its clientele." },
        { image: grace, name: "Grace Afolayan ACIPM", role: "HRPL", text:"In the realm of human resources within the information & communication technology sector, Grace Afolayan ACIPM is a distinguished figure. With a rich history in HR, she brings a wealth of experience to her role as Human Resource Generalist, Recruiter, and People Operations at Tech4mation Ltd. Her expertise extends beyond the ICT sphere, having made impactful contributions to manpower supply, real estate, and logistics industries. As an accredited professional with the ACIPM designation, Grace exemplifies dedication to excellence and continuous learning. Her strategic approach to human capital management has played a pivotal role in fostering a positive work environment at Tech4mation Ltd. Her ability to align recruitment strategies with the company's objectives ensures a workforce that contributes significantly to the organisation's success." },
        { image: vic, name: "Victor Nnonah", role: "Tech lead (Connekt)", text:"" },
        { image: ugo, name: "Ugochi", role: "Board of director ", text:"" },
        { image: prod, name: "Joan Aminu", role: "Product Lead", text:"" },
        { image: sales, name: "Oma Omama", role: "Product Lead", text:""},
        { image: finance, name: "Victor Nnonah", role: "Finance Head ", text:"" },
        { image: finance, name: "Chioma Okere", role: "Disturbance Expert ", text:"" },
    ];

    const [selectedMember, setSelectedMember] = useState(null);

  const handleMemberClick = (member) => {
    setSelectedMember(member);
  };

  const handleCloseModal = () => {
    setSelectedMember(null);
  };

    return (
        <section className={`board ${selectedMember ? "modal-open" : ""}`}>
          {/* <h1>Board of Directors</h1> */}
          <div className="hold">
            {boardMembers.map((member, index) => (
              <div
                key={index}
                className="holder"
                onClick={() => handleMemberClick(member)}
              >
                <img src={member.image} alt={member.name} />
                <h5>{member.name}</h5>
                <p>{member.role}</p>
              </div>
            ))}
          </div>
    
          {/* Modal */}
          {selectedMember && (
            <>
              <div
                className={`modal-overlay ${selectedMember ? "open" : ""}`}
                onClick={handleCloseModal}
              ></div>
              <div className={`modal ${selectedMember ? "open" : ""}`}>
                <button onClick={handleCloseModal}>
                  <img src={x} alt="close" />
                </button>
                <div>
                  <img src={selectedMember.image} alt={selectedMember.name} />
                  <span>
                    <h5>{selectedMember.name}</h5>
                    <p>{selectedMember.text}</p>
                  </span>
                </div>
                {/* Add additional content for the modal */}
              </div>
            </>
          )}
        </section>
      );
}

export default Board;