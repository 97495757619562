import React from "react";
import meet from '../../images/meet.svg';
import idea from '../../images/idea.svg';
import design from '../../images/design.svg';
import build from '../../images/build.svg';
import { Link } from "react-router-dom";


const Process = () =>{
    return(
        <section className="process" id="process">
            <div className="top">
                <h4>OUR PROCESS</h4>
                <h2>We believe in transparency,</h2>
                <p>we are always here to guide you, every step of the way.</p>
            </div>
            <div className="bot">
                <div>
                    <img src={meet} alt=""></img>
                    <h4>Meet & Agree</h4>
                    <p>
                        Contact us through any of our contact platforms and on social media, and we would be at your doorstep in no time.
                    </p>
                </div>
                <div>
                    <img src={idea} alt=""></img>
                    <h4>Idea & Concept</h4>
                    <p>
                        Tell us what you have in mind as we always value our clients input. We conceptualise based on your needs and preferences.
                    </p>
                </div>
                <div>
                    <img src={design} alt=""></img>
                    <h4>Design & Creativity</h4>
                    <p>
                        Our team of season experts and designers are trained to create innovative designs suited uniquely for your need.
                    </p>
                </div>
                <div>
                    <img src={build} alt=""></img>
                    <h4>Build & Install</h4>
                    <p>
                        From the drawing board, to reality, be rest assured, you are getting the best solutions for your home, office and industry.
                    </p>
                </div>
            </div>
            <Link to="d">Get started</Link>
        </section>
    )
}

export default Process;