import React from "react";
import Hero from "../comp/hero";
import Header from "../comp/header";
import AboutUs from "./home/aboutUs";
import Service from "./home/service";
import Process from "./home/process";
import Test from "./home/testi";
import Clients from "./home/clients";
import Footer from "../comp/footer";


const Home = () =>{
    return(
        <section className="home">
        <Header/>
        <div className="holding">
            <Hero title="We are Tech4mation" subtitle="We are renowned for providing interactive and innovative IT solutions for residential and entreprise applications." clicker="learn more"/>
            <AboutUs/>
            <Service/>
            <Process/>
            <Test/>
            <Clients/>
        </div>
        <Footer/>
        </section>
    )
}

export default Home;