import './style/style.css';
import './App.css';
import Home from './pages/home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './pages/about';
import Contact from './pages/contact';
import Serv from './pages/service';
import Lead from './pages/lead';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' exact Component={Home}/>
        <Route path='/about' exact Component={About}/>
        <Route path='/contact' exact Component={Contact}/>
        <Route path='/service' exact Component={Serv}/>
        <Route path='/lead' exact Component={Lead}/>
      </Routes>
    </Router>
  );
}

export default App;
